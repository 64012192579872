<template>
  <a-modal
    destroy-on-close
    v-model="visible"
    :title="type==='70'?'添加-区县代理-'+regionName:type==='80'?'添加-市级代理-'+regionName:'添加-省级代理-'+regionName"
    :width="900"
    :footer='false'
    @cancel="cancel"
  >
    <div class='page-box'>
      <base-screen>
        <a-form-model ref="formRef" layout="inline" :model="queryRef" @submit="handleSearch" @submit.native.prevent>
          <a-form-model-item label="姓名" prop="name">
            <a-input v-model="queryRef.name" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="手机号" prop="phone">
            <a-input v-model="queryRef.phone" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="等级" prop="levelId">
            <a-select style="width: 120px" placeholder="请选择" v-model='queryRef.levelId'>
              <a-select-option :value="item.levelId" :key='item.levelId + index' v-for='(item,index) in levelList'>
                {{item.levelName}}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item label="上级" prop="upAgentName">
            <a-input v-model="queryRef.upAgentName" placeholder="请输入" />
          </a-form-model-item>

          <a-form-model-item>
            <a-button type="primary" @click="handleSearch">查询</a-button>
            <a-button @click="handleReset">重置</a-button>
          </a-form-model-item>
        </a-form-model>
      </base-screen>
      <a-table :rowKey="(record,index) => record.userId" :data-source="tableData" :loading='loading' :columns="columns" :pagination="pagination" @change='tableChange'>
        <div class="table-operations" slot="operation" slot-scope="text, record">
          <a-popconfirm placement="topRight" :title="`确定要添加代理吗？`" ok-text="确定" cancel-text="取消" @confirm="add(record.userId)">
            <a-button type="link">添加为代理</a-button>
          </a-popconfirm>
        </div>
      </a-table>
    </div>
  </a-modal>
</template>

<script>
import { agent } from '@/api'
export default {
  name: 'ModalCommSetting',
  props: {
    visible: Boolean,
    type: String,
    regionCode: String,
    regionName: String,
  },
  data: () => ({
    loading: false,
    queryRef: {
      type: 2,
      levelId: undefined,
    },
    showModal: false,
    pagination: {
      current: 1,
      size: 'small',
      pageSize: 10,
      total: 0,
    },
    confirmLoading: false,
    tableData: [],
    levelList: [],
    columns: [
      {
        title: '姓名',
        dataIndex: 'name',
      },
      {
        title: '手机号',
        dataIndex: 'phone',
      },
      {
        title: '会员等级',
        dataIndex: 'lawLevelName',
      },
      {
        title: '血缘上级',
        dataIndex: 'upAgentName',
        scopedSlots: { customRender: 'upAgentName' },
      },
      {
        title: '加入时间',
        dataIndex: 'joinTime',
      },
      {
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' },
      },
    ],
  }),
  watch: {
    visible: {
      handler (newVal) {
        this.current = '0'
        this.visible = newVal
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    this.getLevelList()
    this.getData()
  },
  methods: {
    changeTab (e) {
      this.queryRef.type = e
      this.pagination.current = 1
      this.getData()
    },
    async getLevelList () {
      const { data, code } = await agent.getTeamLevelList({ relationType: 2 })
      if (code === '00000') {
        this.levelList = data
      }
    },
    async getData () {
      this.loading = true
      const { data, code, page, msg } = await agent.getTeamPage({
        ...this.queryRef,
        current: this.pagination.current,
        size: this.pagination.pageSize,
      })
      this.loading = false
      if (code === '00000') {
        this.tableData = data
        this.pagination.current = page.pageNo
        this.pagination.pageSize = page.pageSize
        this.pagination.total = page.total
      } else {
        this.$message.error(msg || '获取数据失败')
      }
    },
    handleSearch () {
      this.pagination.current = 1
      this.getData()
    },
    handleReset () {
      this.$refs.formRef.resetFields()
      this.getData()
    },
    tableChange ({ current }) {
      this.pagination.current = current
      this.getData()
    },
    async add (userId) {
      const { code, msg } = await agent.userRegionAdd({
        userId: userId,
        type: this.type,
        regionCode: this.regionCode,
        regionName: this.regionName,
      })
      if (code === '00000') {
        this.$emit('confirm', {
          flag: true,
        })
      } else {
        this.$message.error(msg || '设置失败')
      }
    },
    cancel () {
      this.$emit('confirm', {
        flag: false,
      })
    }
  },
}
</script>

<style lang="less" scoped>
.info-box {
  display: flex;
  align-items: center;
  margin: 12px;
  .info-item {
    margin-right: 48px;
  }
}
</style>
