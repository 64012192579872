<template>
  <div class="wrap-cards">
    <div class="tree-card">
      <a-card :loading="loadingTree">
        <div slot="title">
          <div>省市区</div>
        </div>
        <a-tree v-if="treeData.length" :treeData="treeData" :expandedKeys="expandedKeys" :selectedKeys="selectParent ? [selectParent.id] : ['0']" @expand="handleExpand" @select="handleSelect">
          <template #title="node">
            <span>{{ node.title }}</span>
          </template>
        </a-tree>
        <no-data v-else></no-data>
      </a-card>
    </div>
    <div class="form-card">
      <a-card>
        <div slot="title">
          <div>
            代理人
          </div>
        </div>
        <div class="page-box">
          <base-screen>
            <a-form-model ref="queryRef" layout="inline" :model="queryData" @submit.native.prevent>
              <a-form-model-item label="类型" prop='type'>
                <a-select v-model="queryData.type" placeholder="请选择" style="min-width: 120px">
                  <a-select-option value=''>所有类型</a-select-option>
                  <a-select-option value="90">省级代理</a-select-option>
                  <a-select-option value="80">市级代理</a-select-option>
                  <a-select-option value="70">区县代理</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="查询条件" prop="name">
                <a-input style="width: 220px" allowClear v-model="queryData.name" placeholder="请输入姓名/手机号/登录账号" />
              </a-form-model-item>
              <a-form-model-item>
                <a-button type="primary" @click="handleSearch">查询</a-button>
                <a-button @click="handleReset">重置</a-button>
              </a-form-model-item>
            </a-form-model>
          </base-screen>
          <div style='margin-bottom: 16px;'>
            <a-button type='primary' style='margin-right: 16px;' @click='addUser'>添加代理</a-button>
          </div>
          <a-table :pagination="pagination" :loading='loading' :columns="columns" :rowKey="record => record.userRegionId" :dataSource="list" @change='changePage'>
            <template slot="type" slot-scope="text, record">
              <span v-if="record.type === '70'">区县代理</span>
              <span v-if="record.type === '80'">市级代理</span>
              <span v-if="record.type === '90'">省级代理</span>
            </template>
            <template slot="operation" slot-scope="text,record">
              <div class="table-operations">
                <a-popconfirm placement="topRight" title="确定要删除吗？" ok-text="确定" cancel-text="取消" @confirm="()=>deleteDl(record.userRegionId)">
                  <a-button type="link">删除代理</a-button>
                </a-popconfirm>
              </div>
            </template>
          </a-table>
          <model-team-select  @confirm="handleConfirm" :type="type" :regionCode="regionCode" :regionName="regionName" :visible.sync="showModal" />
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import { common, agent } from '@/api'
import { message } from 'ant-design-vue'
import ModelTeamSelect from './ModelTeamSelect'
export default {
  name: 'PageAgentManagement',
  components: {
    ModelTeamSelect,
  },
  data () {
    return {
      loadingTree: false,
      userList: [],
      treeData: [],
      expandedKeys: [],
      selectParent: {
        id: '0',
      },
      showModal: false,
      type: '',
      regionCode: '',
      regionName: '',
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      loading: false,
      queryData: {},
      list: [],
      pagination: {
        current: 1,
        size: 'small',
        pageSize: 10,
        total: 0,
        showTotal: (total) => `共 ${total} 条`,
      },
      columns: [
        {
          title: '类型',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
        },
        {
          title: '地区',
          dataIndex: 'regionName'
        },
        {
          title: '姓名',
          dataIndex: 'name',
        },
        {
          title: '联系方式',
          dataIndex: 'phone',
        },
        {
          title: '登陆账号',
          dataIndex: 'loginName',
        },
        {
          title: '操作',
          width: 280,
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
        },
      ],
    }
  },
  async mounted () {
    const initExpandedKeys = await this.getTreeData()
    this.expandedKeys = initExpandedKeys
    this.getUserData()
  },
  methods: {
    async getTreeData () {
      this.loadingTree = true
      let { data, msg, code } = await common.getRegionTree({ flag: true })
      if (code === '00000') {
        this.loadingTree = false
        this.treeData = this.handleTreeData(data)
        return this.treeData[0] && this.treeData[0].id ? [this.treeData[0].id] : []
      } else {
        message.error(msg || '网络错误，请重试')
      }
    },
    handleTreeData (tree) {
      for (let node of tree) {
        node.title = node.regionName
        node.key = node.regionCode
        node.id = node.regionCode
        node.name = node.regionName
        node.scopedSlots = {
          icon: 'icon',
          title: 'title',
        }
        if (node.children) {
          node.children = this.handleTreeData(node.children)
        }
      }
      return tree
    },
    handleSelect (_, event) {
      let node = event.node.$options.propsData.dataRef
      if (this.selectParent && node.id === this.selectParent.id) return
      this.selectParent = node
      this.pagination.current = 1
      this.getUserData()
    },
    handleExpand (expandedKeys) {
      this.expandedKeys = expandedKeys
    },
    // 节流
    debounce (fn, delay) {
      clearTimeout(fn.timeoutId)
      fn.timeoutId = setTimeout(() => {
        fn.call(this)
      }, delay)
    },
    async getUserData () {
      this.loading = true
      const { data, code, msg, page } = await agent.getUserPage({
        current: this.pagination.current,
        size: this.pagination.pageSize,
        type: this.queryData.type,
        name: this.queryData.name,
        regionCode: this.selectParent && this.selectParent.id !== '0' ? this.selectParent.id : '',
      })
      this.loading = false
      if (code === '00000') {
        this.list = data
        this.pagination.total = page.total
      } else {
        this.$message.error(msg || '获取数据失败')
      }
    },
    changePage ({ current }) {
      this.pagination.current = current
      this.getUserData()
    },
    handleSearch () {
      this.pagination.current = 1
      this.getUserData()
    },
    handleReset () {
      this.$refs.queryRef.resetFields()
      this.pagination.current = 1
      this.getUserData()
    },
    async addUser () {
      if (!this.selectParent.id) return this.$message.warn('请选择一个具体区域')
      if (this.selectParent.id === '0') return this.$message.warn('请选择一个具体区域')
      if (this.selectParent.id.length === 2) {
        this.type = '90'
      }
      if (this.selectParent.id.length === 4) {
        this.type = '80'
      }
      if (this.selectParent.id.length === 6) {
        this.type = '70'
      }
      this.regionCode = this.selectParent.id
      this.regionName = this.selectParent.title
      this.showModal = true
    },
    async deleteDl (userRegionId) {
      const { code, msg } = await agent.deleteDl({
        userRegionId: userRegionId,
      })
      if (code === '00000') {
        this.$message.success('删除成功')
        this.getUserData()
      } else {
        this.$message.error(msg || '删除失败')
      }
    },
    handleConfirm ({ flag }) {
      this.showModal = false
      if (flag) {
        this.getUserData()
      }
    },
  },
}
</script>

<style lang="less" scoped>
.wrap-cards {
  height: 100%;
}
.tree-card {
  min-width: 250px;
  min-height: 100%;
  user-select: none;
  .menu-icon {
    margin-right: 3px;
  }
}
.form-card {
  flex: 1;
  min-height: 100%;
  margin-left: 10px;
}
/deep/.ant-card-head {
  font-size: 18px;
}
::v-deep .ant-card-bordered {
  min-height: 100%;
}
.request-info p {
  font-size: 14px;
  color: #333;
  margin-bottom: 8px;
  line-height: 20px;
}
.flow-box {
  overflow: auto;
  margin: 16px 0;
  .table-box {
    display: flex;
    width: 1200px;
    text-align: center;
    box-sizing: border-box;
    line-height: 32px;
    .c-box {
      width: 80px;
      font-size: 14px;
      border-right: 1px solid #fff;
      .head {
        background: #1890ff;
        color: #fff;
      }
    }
  }
}
</style>
